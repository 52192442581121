import React, { Component } from "react";

import { Row, Col, CardBody } from "reactstrap";

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <CardBody>
        <div>
          <h5>Welcome to Schuman Financial's onboarding</h5>
          <p>
            Thank you for placing your trust in us. We deeply value your
            interest in our services.
          </p>
          <hr />
          <Row>
            <Col className="col-xl-1 col-md-2">
              <h1 className="m-0">
                <i className="mdi mdi-clock-check-outline text-primary text-right"></i>
              </h1>
            </Col>
            <Col className="col-10 align-self-end">
              <p>
                This <strong>quick 2-minute survey</strong> is required to
                access the minting and redemption of Schuman EMTs.
              </p>
            </Col>
          </Row>
          <p className="mt-4">
            Please complete the following form so our team can assess your
            eligibility. After our team confirms your eligibility, KYC and AML
            verification will be conducted through our trusted partner, Synapse,
            to ensure the highest security and compliance standards.
          </p>
          <p className="mt-4">
            <strong>
              We are excited to welcome you to our growing ecosystem!
            </strong>
          </p>
          <div className="mt-3">
            <div
              className="btn btn-primary btn-block waves-effect waves-light"
              onClick={() => this.props.handleChangeView("cgu")}
            >
              Start onboarding
            </div>
          </div>
        </div>
      </CardBody>
    );
  }
}

export default Welcome;
