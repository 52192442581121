import React, { Component } from "react";
import { Row, Col, CardBody, Input } from "reactstrap";
import { Line } from "rc-progress";
import NumberFormat from "react-number-format";

const validator = require("email-validator");

class CompanyInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      companyName: null,
      businessSector: null,
      registrationNumber: null,
      fullAddress: null,
      legalForm: null,
      purpose: "onboarding",
      use: null,
      contactMail: null,
      expectedVolume: null,
      error: null,
    };
  }

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  validateCompany = () => {
    if (
      (!this.state.companyName ||
        !this.state.businessSector ||
        !this.state.fullAddress ||
        !this.state.expectedVolume ||
        !this.state.use ||
        !this.state.purpose) &&
      !this.props.company.companyName
    ) {
      this.setState({ error: "Please fill all required information" });
    } else if (
      !validator.validate(this.state.contactMail) &&
      !validator.validate(this.props.company.contactMail)
    ) {
      this.setState({ error: "Email format is incorrect" });
    } else {
      this.setState({ error: null });
      this.props.submitCompany({
        companyName: this.state.companyName || this.props.company.companyName,
        businessSector:
          this.state.businessSector || this.props.company.businessSector,
        fullAddress: this.state.fullAddress || this.props.company.fullAddress,
        purpose: this.state.purpose || this.props.company.purpose,
        expectedVolume:
          this.state.expectedVolume || this.props.company.expectedVolume,
        use: this.state.use || this.props.company.use,
        contactMail: this.state.contactMail || this.props.company.contactMail,
      });
    }
  };

  render() {
    return (
      <CardBody>
        <div>
          <h5>2. Company information</h5>
          <Line percent={40} strokeWidth={2} strokeColor="#2eecc7" />
          <Row className="mt-4">
            <Col className="col-xl-1 col-md-2">
              <h1 className="m-0">
                <i className="mdi mdi-domain text-primary text-right"></i>
              </h1>
            </Col>
            <Col className="col-10 align-self-end">
              <p>
                Fill all required information regarding the identification of
                your company
              </p>
            </Col>
          </Row>
          <div className="form-group">
            <p className="mt-2 mb-1">
              <strong>Company name</strong>{" "}
              <span className="text-danger">*</span>
            </p>
            <Input
              name="company_name"
              className="form-control"
              placeholder="Company name"
              type="text"
              value={this.state.companyName || this.props.company.companyName}
              onChange={(e) => this.setState({ companyName: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <p className="mt-2 mb-1">
              <strong>Business category</strong>{" "}
              <span className="text-danger">*</span>
            </p>
            <Input
              name="business_sector"
              className="form-control"
              placeholder="Trading platform"
              type="text"
              value={
                this.state.businessSector || this.props.company.businessSector
              }
              onChange={(e) =>
                this.setState({ businessSector: e.target.value })
              }
              required
            />
          </div>
          <div className="form-group">
            <p className="mt-2 mb-1">
              <strong>Address of domiciliation and/or head office</strong>{" "}
              <span className="text-danger">*</span>
            </p>
            <Input
              name="address"
              className="form-control"
              placeholder="Full address (street, state, city, country)"
              type="text"
              value={this.state.fullAddress || this.props.company.fullAddress}
              onChange={(e) => this.setState({ fullAddress: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <p className="mt-2 mb-1">
              <strong>Purpose</strong> <span className="text-danger">*</span>
            </p>
            <p>
              <input
                type="radio"
                name="purpose"
                value={"onboarding"}
                style={{ width: "auto" }}
                defaultChecked
              />{" "}
              Onboarding
              <input
                type="radio"
                name="purpose"
                value={"redemption"}
                style={{ width: "auto", marginLeft: 14 }}
              />{" "}
              Solely for redemption
            </p>
          </div>
          <div className="form-group">
            <p className="mt-2 mb-1">
              <strong>How will use EUROP</strong>{" "}
              <span className="text-danger">*</span>
            </p>
            <Input
              name="relationship"
              className="form-control"
              placeholder="Tell us more how you will use EUROP"
              type="text"
              value={this.state.use || this.props.company.use}
              onChange={(e) => this.setState({ use: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <p className="mt-2 mb-1">
              <strong>Expected annual volume</strong>{" "}
              <span className="text-danger">*</span>
            </p>
            <NumberFormat
              thousandSeparator={true}
              className="form-control"
              placeholder={"EUROP amount"}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                this.setState({
                  volume: value,
                  expectedVolume: formattedValue,
                });
              }}
            />
          </div>
          <div className="form-group">
            <p className="mt-2 mb-1">
              <strong>Contact mail</strong>{" "}
              <small>
                (Email that will receive all the information & notifications
                regarding your onboarding process & validation)
              </small>{" "}
              <span className="text-danger">*</span>
            </p>
            <Input
              name="relationship"
              className="form-control"
              placeholder="Contact mail"
              type="email"
              value={this.state.contactMail || this.props.company.contactMail}
              onChange={(e) => this.setState({ contactMail: e.target.value })}
              required
            />
          </div>
          <p>
            <span className="text-danger">*</span>{" "}
            <small>All mandatory fields</small>
          </p>
          {this.state.error && (
            <p className="my-1 text-danger">
              <strong>{this.state.error}</strong>
            </p>
          )}
          <Row className="mt-3">
            <Col className="col-4">
              <div
                className="btn btn-secondary btn-block waves-effect waves-light"
                onClick={() => this.props.handleChangeView("cgu")}
              >
                {"<"} Back
              </div>
            </Col>
            <Col className="col-8">
              <div
                className="btn btn-primary btn-block waves-effect waves-light"
                onClick={this.validateCompany}
              >
                Validate company info
              </div>
            </Col>
          </Row>
        </div>
      </CardBody>
    );
  }
}

export default CompanyInfo;
