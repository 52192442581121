import React, { Component } from "react";

import { Row, Col, Card, Container } from "reactstrap";
import { withRouter } from "react-router-dom";

import Copyright from "../../../components/Common/Copyright";
import Welcome from "./welcome";
import Cgu from "./cgu";
import CompanyInfo from "./companyinfo";
import Owners from "./owners";
import TxInfo from "./txinfo";
import DocUpload from "./docupload";
import Complete from "./complete";
import back from "../../../assets/images/back.jpg";

import { registerCompany } from "../../../helpers/api";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "welcome",
      owners: [],
      company: {},
      txinfo: {},
      kbis: {},
      incorporation: {},
    };
  }

  handleChangeView = (view) => {
    this.setState({ view });
  };

  submitOwners = (owners) => {
    this.setState({ owners, view: "tx_info" });
    let salvusReg = JSON.parse(
      window.localStorage.getItem("salvus_registration")
    );
    salvusReg.owners = owners;
    window.localStorage.setItem(
      "salvus_registration",
      JSON.stringify(salvusReg)
    );
  };

  submitCompany = (company) => {
    console.log(company);
    this.setState({ company, view: "tx_info" });
    if (window.localStorage.getItem("salvus_registration")) {
      let salvusReg = JSON.parse(
        window.localStorage.getItem("salvus_registration")
      );
      salvusReg.company = company;
    } else {
      let salvusReg = {};
      salvusReg.company = company;
      window.localStorage.setItem(
        "salvus_registration",
        JSON.stringify(salvusReg)
      );
    }
  };

  submitTxinfo = (txinfo) => {
    this.setState({ txinfo, view: "doc" });
    let salvusReg = JSON.parse(
      window.localStorage.getItem("salvus_registration")
    );
    salvusReg.txinfo = txinfo;
    window.localStorage.setItem(
      "salvus_registration",
      JSON.stringify(salvusReg)
    );
  };

  handleChange = (value) => {
    this.setState({ value });
    // if value is null recaptcha expired
    if (value === null) this.setState({ expired: "true" });
  };

  submitForm = async () => {
    const resp = await registerCompany({
      companyName: this.state.company.companyName,
      businessSector: this.state.company.businessSector,
      fullAddress: this.state.company.fullAddress,
      expectedVolume: this.state.company.expectedVolume,
      use: this.state.company.use,
      purpose: this.state.company.purpose,
      contactMail: this.state.company.contactMail,
      iban: this.state.txinfo.iban,
      bic: this.state.txinfo.bic,
      ethPkh: this.state.txinfo.ethPkh,
      recaptcha: this.state.value,
    });
    if (resp.status === "SUCCESS") {
      return true;
    } else {
      return false;
    }
  };

  componentDidMount = () => {
    try {
      if (window.localStorage.getItem("salvus_registration")) {
        const salvusReg = JSON.parse(
          window.localStorage.getItem("salvus_registration")
        );
        this.setState({
          owners: salvusReg.owners || [],
          company: salvusReg.company || {},
          txinfo: salvusReg.txinfo || {},
          kbis: salvusReg.kbis || {},
          incorporation: salvusReg.incorporation || {},
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="account-pages pt-sm-5 bg-welcome"
          style={{ backgroundImage: `url('${back}')` }}
        >
          <Container>
            <Row className="justify-content-center">
              <Col md={10} lg={8} xl={6}>
                <div className="wcard-schuman">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4"></div>
                    </Col>
                    <Col className="col-5 align-self-end"></Col>
                  </Row>
                </div>
                <Card className="overflow-hidden card-welcome">
                  {this.state.view === "welcome" && (
                    <Welcome handleChangeView={this.handleChangeView} />
                  )}
                  {this.state.view === "cgu" && (
                    <Cgu handleChangeView={this.handleChangeView} />
                  )}
                  {this.state.view === "company_info" && (
                    <CompanyInfo
                      submitCompany={this.submitCompany}
                      handleChangeView={this.handleChangeView}
                      company={this.state.company}
                    />
                  )}
                  {this.state.view === "owners" && (
                    <Owners
                      handleChangeView={this.handleChangeView}
                      submitOwners={this.submitOwners}
                      owners={this.state.owners}
                    />
                  )}
                  {this.state.view === "tx_info" && (
                    <TxInfo
                      handleChangeView={this.handleChangeView}
                      submitTxinfo={this.submitTxinfo}
                      txinfo={this.state.txinfo}
                    />
                  )}
                  {this.state.view === "doc" && (
                    <DocUpload
                      handleChangeView={this.handleChangeView}
                      handleChange={this.handleChange}
                      submitForm={this.submitForm}
                      recaptcha={this.state.value}
                    />
                  )}
                  {this.state.view === "complete" && <Complete />}
                </Card>
                <div className="mt-5 text-center">
                  <Copyright />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Register);
