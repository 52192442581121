import React, { Component } from "react";
import { Row, Col, CardBody } from "reactstrap";
import { Line } from "rc-progress";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import Loader from "react-loader-spinner";

import { RECAPTCHA_KEY } from "../../../constants";

class DocUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
    };
  }

  validateDocument = async () => {
    if (!this.props.recaptcha)
      return this.setState({
        processing: false,
        error: "Error: Please validate reCaptcha.",
      });
    this.setState({ processing: true, error: null });
    const status = await this.props.submitForm();
    if (status) {
      window.localStorage.removeItem("salvus_registration");
      this.props.handleChangeView("complete");
    } else {
      this.setState({
        processing: false,
        error: "Error: Could not complete the registration.",
      });
    }
  };

  render() {
    return (
      <CardBody>
        <div>
          <h5>4. Validate application</h5>
          <Line percent={100} strokeWidth={2} strokeColor="#2eecc7" />
          <Row className="mt-4">
            <Col className="col-xl-1 col-md-2">
              <h1 className="m-0">
                <i className="mdi mdi-check-bold text-primary text-right"></i>
              </h1>
            </Col>
            <Col className="col-10 align-self-end">
              <p>
                Please make sure that your application is accurate before
                submission.
              </p>
            </Col>
          </Row>
          {this.state.error && (
            <p className="my-1 text-danger">
              <strong>{this.state.error}</strong>
            </p>
          )}
          <HCaptcha
            sitekey={RECAPTCHA_KEY}
            onVerify={this.props.handleChange}
          />
          {this.state.processing ? (
            <>
              <Loader type="Puff" color="#545454" height={50} width={50} />
              <p>Processing... Please wait.</p>
            </>
          ) : (
            <Row className="mt-3">
              <Col className="col-4">
                <div
                  className="btn btn-secondary btn-block waves-effect waves-light"
                  onClick={() => this.props.handleChangeView("tx_info")}
                >
                  {"<"} Back
                </div>
              </Col>
              <Col className="col-8">
                <div
                  className="btn btn-primary btn-block waves-effect waves-light"
                  onClick={this.validateDocument}
                >
                  Submit pre-registration
                </div>
              </Col>
            </Row>
          )}
        </div>
      </CardBody>
    );
  }
}

export default DocUpload;
