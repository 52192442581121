import React, { Component } from "react";

import { Row, Col, CardBody } from "reactstrap";

import { Line } from "rc-progress";

class Complete extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <CardBody>
        <div>
          <h5>Pre-registration successfully complete</h5>
          <Line percent={100} strokeWidth={2} strokeColor="#08EA8A" />
          <Row className="mt-4">
            <Col className="col-xl-1 col-md-2">
              <h1 className="m-0">
                <i className="mdi mdi-party-popper text-primary text-right"></i>
              </h1>
            </Col>
            <Col className="col-10 align-self-end">
              <p>
                Thank you for your interest in working with Schuman Financial.
                Your pre-registration is now complete. Our team will process
                your application and contact you as soon as possible. In case of
                a positive response, you will receive a link to complete the
                onboarding process through our partner Synapse.
              </p>
              <p>
                <strong>
                  Thanks a lot for you trust.{" "}
                  <a
                    href="https://schuman.io"
                    rel={"noreferrer"}
                    target={"_blank"}
                    className="text-success"
                  >
                    Back to Schuman.io
                  </a>
                </strong>
              </p>
            </Col>
          </Row>
        </div>
      </CardBody>
    );
  }
}

export default Complete;
