import React, { Component } from "react";

class CguContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="cgu_content">
        <h5>
          SCEME's SC-PaaS Platform <br/>
          General Terms and Conditions of Use (GTCU)
        </h5>
        <p className="mt-2">
          <small>Effective Date: 4 November 2024</small>
        </p>
        <p className="mt-2">
          <small className="d-block">
            These General Terms and Conditions of Use (‘GTCU’) govern your
            access to and use of the SC-PaaS platform (the ‘Platform’) owned and
            operated by SCEME SAS (&quot;We&quot;, &quot;us&quot;,
            &quot;our&quot;).
          </small>
        </p>
        <p className="ml-2 mt-3">
          <strong>1. Acceptance of the Terms</strong>
        </p>
        <small className="d-block">
          By using the Platform, you acknowledge that you have read, understood
          and accepted these GTCU. If you do not accept these GTCU, you are not
          authorised to use the Platform.
        </small>
        <small className="d-block mt-2">
          SCEME reserves the right to modify these GTCU at any time. Any changes
          will take effect immediately upon publication on the Platform. It is
          your responsibility to consult these GTCU regularly to keep informed
          of any updates.
        </small>
        <small className="d-block mt-2">
          Continued use of the Services after changes constitute acceptance of
          the GTCU. If you do not agree to the amended Terms, you must cease
          using the Services.
        </small>
        <small className="d-block mt-2">
          We may update Website content without notice but do not guarantee
          completeness or up-to-date information.
        </small>
        <p className="ml-2 mt-3">
          <strong>2. Definitions</strong>
        </p>
        <small className="d-block mt-2">
          <strong>Client:</strong> Refers to all software components and web,
          server and mobile application services, which are the exclusive
          property of SCEME and licensed to Schuman Financial under the user
          license
        </small>
        <small className="d-block mt-2">
          <strong>Platform:</strong> Any person or entity accessing the Platform
          via Schuman Financial.
        </small>
        <small className="d-block mt-2">
          <strong>Issuer:</strong> The Schuman Financial company that issues and
          manages EUROP stablecoins via the Platform.
        </small>
        <small className="d-block mt-2">
          <strong>Stablecoin:</strong> A cryptocurrency linked to a reference
          value, such as a fiduciary currency.
        </small>
        <small className="d-block mt-2">
          <strong>Services:</strong> All features and services provided via the
          Platform.
        </small>
        <small className="d-block mt-2">
          <strong>Data:</strong>
          Refers to any type of information, content, or data in any form in
          relation to the operation of the Service, digital assets, or Clients
          of the Service, or any data collected or processed by SCEME at the
          request of Schuman Financial. This includes both structured and
          unstructured data, whether generated by users, administrators, or
          automated systems. In all cases, the Data remains the property of
          Schuman Financial.
        </small>
        <p className="ml-2 mt-3">
          <strong>3. Service</strong>
        </p>
        <small className="d-block">
          The solution provided by SCEME allows Schuman Financial to operate a
          service for issuing and managing Stablecoins based on Smart Contracts
          issued on different blockchains. Through the SaaS Solution dedicated
          to Schuman Financial, the Platform allows the management of the
          functions of the EUROP Stablecoin, as well as the provision of
          appropriate tools for effective governance of the management of the
          rights of use of Stablecoins for Schuman Financial and their Clients.
        </small>
        <small className="d-block mt-2">
          The Platform also offers dedicated interfaces to Schuman
          Financials&#39; Clients so that they can carry out their onboarding,
          as well as their Stablecoin purchase or sale transactions with Schuman
          Financial.
        </small>
        <small className="d-block mt-2">
          By accessing or using the Services, you agree that SCEME does not
          offer execution, settlement, or clearing services of any kind and is
          not liable for the execution, settlement, or clearing of transactions
          automated through or by the Services. You acknowledge and agree that
          the execution and settlement of Digital Asset or smart contract-based
          transactions take place directly on the Ethereum blockchain or any
          other blockchain or Layer 2 utilized by SCEME to deliver the Services.
        </small>
        <small className="d-block mt-2">
          SCEME provides Schuman Financial with a secure platform for issuing
          and managing EUROP stablecoins based on blockchain technology. Schuman
          Financial, as the issuer, offers EUROP stablecoins to qualified users
          through authorized crypto-asset trading platforms. SCEME does not sell
          or distribute EUROP directly to users and is not responsible for any
          third-party transactions involving the stablecoin. Users must comply
          with applicable laws and regulations when transacting EUROP on
          authorized exchanges.
        </small>
        <p className="ml-2 mt-3">
          <strong>4. Access to the Platform</strong>
        </p>
        <small className="d-block">
          As a condition to accessing the Services, you acknowledge, understand,
          and agree to the following:
        </small>
        <small className="d-block mt-2">
          <strong>- Age Requirement:</strong> To use our Services, you must be
          at least 18 years old.
        </small>
        <small className="d-block mt-2">
          <strong>- Geographic Restrictions:</strong> You may not use the
          Services if you are located in a country where they are prohibited or
          subject to economic sanctions.
        </small>
        <small className="d-block mt-2">
          <strong>- Lawful Use:</strong> Your use of the Services must comply
          with all applicable laws and regulations.
        </small>
        <small className="d-block mt-3">
          <strong>
            <u>Corporate Use:</u>
          </strong>
        </small>
        <small className="d-block mt-1">
          <strong>Authorized Representative:</strong> If you are using the
          Services on behalf of a legal entity, you represent that you have the
          authority to bind that entity to these Terms.
        </small>
        <small className="d-block mt-3">
          <strong>
            <u>Technical Restrictions:</u>
          </strong>
        </small>
        <small className="d-block mt-1">
          <strong>VPN Prohibition:</strong> You may not use a virtual private
          network (VPN) or any other similar technology to circumvent any usage
          restrictions.
        </small>
        <small className="d-block mt-3">
          <strong>
            <u>Service Availability:</u>
          </strong>
        </small>
        <small className="d-block mt-1">
          <strong>Service Interruptions:</strong> The Services may be
          temporarily unavailable due to maintenance, upgrades, or unforeseen
          circumstances, including issues related to underlying blockchain
          infrastructure.
        </small>
        <small className="d-block mt-1">
          <strong>No Guarantees:</strong> We do not guarantee uninterrupted or
          error-free service.
        </small>
        <small className="d-block mt-3">
          <strong>
            <u>Service Modifications:</u>
          </strong>
        </small>
        <small className="d-block mt-1">
          <strong>Changes:</strong> We reserve the right to modify, suspend, or
          discontinue the Services at any time without prior notice.
        </small>
        <small className="d-block mt-3">
          <strong>
            <u>Your Responsibility:</u>
          </strong>
        </small>
        <small className="d-block mt-1">
          <strong>Sole Responsibility:</strong> You are solely responsible for
          your use of the Services and the security of your Digital Assets.
        </small>
        <small className="d-block mt-1">
          <strong>Third-Party Transactions:</strong> We are not responsible for
          the actions or omissions of third parties involved in your
          transactions.
        </small>
        <small className="d-block mt-3">
          <strong>
            <u>Eligibility:</u>
          </strong>
        </small>
        <small className="d-block mt-1">
          The use of the Platform is reserved for entities or individuals who
          have passed the required compliance checks by Schuman Financial.
          Schuman Financial is responsible for validating the identity and
          compliance of its end users.
        </small>
        <small className="d-block mt-1">
          <strong>User Account:</strong> To access the Services the user must
          log in using the identification details provided by Schuman Financial.
          Clients are responsible for maintaining the confidentiality of their
          login details and all activities that take place under their account.
        </small>
        <small className="d-block mt-3">
          <strong>Technical details of connection to the Platform:</strong>
        </small>
        <small className="d-block">
          - Account creation is performed by Schuman Financial upon completion
          of diligences
        </small>
        <small className="d-block">
          - Client receive their authentication details by email from the
          Platform
        </small>
        <small className="d-block">
          - Authentication service is a 2FA module operated by Trustbuilder
        </small>
        <p className="ml-2 mt-3">
          <strong>5. User Commitment</strong>
        </p>
        <small className="d-block mt-1">
          <strong>Digital Asset Ownership:</strong> You have full ownership of
          or valid authorization to use any Digital Assets employed in
          connection with the Services.
        </small>
        <small className="d-block mt-1">
          <strong>Legal Compliance:</strong> Your use of the Services will
          comply with all applicable laws and regulations, and you will not use
          the Services if prohibited by law.
        </small>
        <small className="d-block mt-1">
          <strong>Information Accuracy:</strong> All information you provide is
          accurate, complete, and up-to-date.
        </small>
        <small className="d-block mt-1">
          <strong>Security Measures:</strong> You will maintain the security and
          confidentiality of your private keys, passwords, API keys, and other
          credentials associated with your account.
        </small>
        <small className="d-block mt-1">
          <strong>Independent Judgment:</strong> You will exercise your own
          judgment when making investment decisions and will not rely on any
          information received through the Services as a recommendation.
        </small>
        <small className="d-block mt-1">
          <strong>Professional Advice:</strong> You will seek appropriate
          professional advice, such as legal, tax, or accounting counsel, to
          protect your interests.
        </small>
        <small className="d-block mt-1">
          <strong>No Endorsements:</strong> You will not treat any content,
          email, or information received through the Services as an endorsement
          or recommendation by SCEME unless explicitly stated.
        </small>
        <p className="ml-2 mt-3">
          <strong>6. Prohibited Uses</strong>
        </p>
        <small className="d-block mt-1">
          You must use the Services lawfully and in accordance with these Terms.
          You agree not to use the Services for the following Prohibited Uses:
        </small>
        <small className="d-block mt-1">
          <strong>Illegal Activities:</strong> Engage in transactions that
          violate any applicable law or regulation, including laws related to
          data export.
        </small>
        <small className="d-block mt-1">
          <strong>Harm to Minors:</strong> Exploit, harm, or attempt to exploit
          minors through inappropriate content, requests for personal
          information, or other means.
        </small>
        <small className="d-block mt-1">
          <strong>Impersonation:</strong> Impersonate or attempt to impersonate
          SCEME, a Company employee, another user, or any other person or
          entity.
        </small>
        <small className="d-block mt-1">
          <strong>Infringement or Harm:</strong> Infringe upon the rights of
          others, engage in illegal, threatening, fraudulent, or harmful
          activities, or use the Services for any unlawful purpose.
        </small>
        <small className="d-block mt-1">
          <strong>Disruption:</strong> Restrict or inhibit anyone&#39;s use or
          enjoyment of the Services, or engage in conduct that may harm, offend,
          or expose SCEME or other users to liability.
        </small>
        <small className="d-block mt-1">
          <strong>Unauthorized Access:</strong> Use a robot, bot, spider, or
          other automatic device to monitor or copy materials on the Services
          for unauthorized purposes.
        </small>
        <small className="d-block mt-1">
          <strong>Illegal Activities:</strong> Use the Services to disguise the
          proceeds of or further any breach of law or regulation, or to deal in
          contraband Digital Assets, funds, or proceeds.
        </small>
        <small className="d-block mt-1">
          <strong>Unauthorized Funds:</strong> Use funds or other value on the
          Website that were not legally obtained or do not belong to you.
        </small>
        <small className="d-block mt-1">
          <strong>Interference:</strong> Interfere with or subvert the rights or
          obligations of SCEME, other users, or third parties.
        </small>
        <small className="d-block mt-1">
          <strong>Detrimental Conduct:</strong> Engage in conduct that is
          detrimental to SCEME, other Service users, or third parties.
        </small>
        <small className="d-block mt-1">
          <strong>False Information:</strong> Falsify, omit, or provide
          misleading information requested by SCEME during registration or
          related to your activities on the Website or Services.
        </small>
        <small className="d-block mt-1">
          <strong>Unauthorized Access:</strong> Reverse-engineer, decompile, or
          disassemble software running on the Website, or attempt to harm SCEME
          through your access to the Services. This does not limit your free
          speech rights under applicable law.
        </small>
        <small className="d-block mt-1">
          <strong>Encourage Prohibited Activities:</strong> Encourage, induce,
          or assist others in engaging in any prohibited activities.
        </small>
        <p className="ml-2 mt-3">
          <strong>7. Prohibited Uses</strong>
        </p>
        <small className="d-block mt-1">
          By using the Services, you acknowledge and agree to the following:
        </small>
        <small className="d-block mt-1">
          <strong>Market Volatility:</strong> Digital Asset markets are subject
          to rapid fluctuations in regulation, liquidity, market depth, and
          trading dynamics. You are solely responsible for your account and the
          accuracy of your Digital Asset information, even if discrepancies
          exist on the Website.
        </small>
        <small className="d-block mt-1">
          <strong>Transaction Costs and Risks:</strong> The costs and speeds of
          transactions on blockchain-based systems like Ethereum can vary
          significantly and may increase. There is a risk of losing value in
          your Digital Assets while interacting with the Services. We are not
          liable for these variables or risks.
        </small>
        <small className="d-block mt-1">
          <strong>Cryptographic Risks:</strong> You understand the inherent
          risks associated with cryptographic and blockchain- based systems and
          possess knowledge of Digital Assets like Ethereum, and ERC-20 tokens.
        </small>
        <small className="d-block mt-1">
          <strong>No Investment Advice:</strong> SCEME is not a registered
          broker-dealer, investment advisor, or manager. We do not offer
          investment advice, participate in transactions, or recommend specific
          investments.
        </small>
        <small className="d-block mt-1">
          <strong>Blockchain Network Risks:</strong> We are not responsible for
          the operation of blockchain networks or third-party decentralized
          applications underlying the Services. There is no guarantee of their
          functionality, security, or availability.
        </small>
        <small className="d-block mt-1">
          <strong>Blockchain Changes:</strong> Blockchain networks are subject
          to changes, such as &quot;forks,&quot; that may impact the Services.
        </small>
        <small className="d-block mt-1">
          <strong>Private Key Security:</strong> You are solely responsible for
          securing your private keys. Losing control of your private keys may
          result in permanent loss of access to your Digital Assets.
        </small>
        <small className="d-block mt-1">
          <strong>No Recovery:</strong> Neither SCEME nor any other entity can
          retrieve or protect your Digital Assets if your private keys are lost.
        </small>
        <small className="d-block mt-1">
          <strong>No Professional Advice:</strong> We do not provide
          professional or investment advice. Information on the Website is for
          informational purposes only and should not be construed as investment
          advice.
        </small>
        <small className="d-block mt-1">
          <strong>Independent Advice:</strong> You should seek independent
          professional advice before making financial decisions related to the
          Services.
        </small>
        <small className="d-block mt-1">
          <strong>No Fiduciary Duties:</strong> We owe no fiduciary duties to
          you and disclaim any such duties.
        </small>
        <small className="d-block mt-1">
          <strong>Development Risks:</strong> The Services are under
          development, which may lead to technological risks like delays in
          trades or incorrect information display. You acknowledge these risks
          and assume sole responsibility for any potential consequences.
        </small>
        <small className="d-block mt-1">
          By using the Services, you irrevocably waive, release, and discharge
          all claims against SCEME and its affiliates related to any of the
          risks set forth above.
        </small>
        <p className="ml-2 mt-3">
          <strong>8. Suspension du Compte</strong>
        </p>
        <small className="d-block mt-1">
          We may, at our discretion, modify, suspend, or disable the Services,
          in whole or in part, temporarily or permanently. Upon termination of
          your access, your usage rights immediately cease. We are not liable
          for any losses resulting from service modifications or termination.
        </small>
        <p className="ml-2 mt-3">
          <strong>9. Pricing, payments &amp; fees</strong>
        </p>
        <small className="d-block mt-1">
          Use of the Platform may be subject to charges agreed between the
          Client and the Schuman Financial. SCEME do not collect any payment
          directly from the Client as an end user.
        </small>
        <small className="d-block mt-1">
          You are responsible for all on-chain transaction costs associated with
          on-chain transfers of any Digital Assets and all other fees reflected
          on the Website at the time of your use of the Services.
        </small>
        <p className="ml-2 mt-3">
          <strong>10. Security and confidentiality</strong>
        </p>
        <small className="d-block mt-1">
          You are responsible for maintaining the confidentiality of your
          account information and all activities under your account. Report any
          unauthorized use or security breaches promptly. We are not liable for
          losses due to unauthorized use of your account, password, or private
          keys, even if without your knowledge. Exercise caution when accessing
          your account from public or shared computers to prevent others from
          viewing your credentials.
        </small>
        <p className="ml-2 mt-3">
          <strong>11. Data collection and use</strong>
        </p>
        <small className="d-block mt-1">
          <strong>11.1. Processing of personal data</strong>
        </small>
        <small className="d-block mt-1">
          Contact details of the main contact person for topics related to the
          protection of personal data
        </small>
        <small className="d-block mt-1">
          Controler : dpo@schuman.io
          <br />
          Processor : dpo@sceme.io
        </small>
        <small className="d-block mt-1">
          <strong>11.2. Purposes of the processing(s):</strong>
        </small>
        <small className="d-block mt-1">
          - Simple and quick display via the platform.
          <br />
          - Access management (login and password).
          <br />
          - Logs of user interactions / platform functionalities.
          <br />
          - Collection of data (last name, first name and email) or display on
          the Platform.
          <br />
          - Recording of connection sources i.e. IP address and user-agent, for
          logs files.
          <br />
        </small>
        <small className="d-block mt-1">
          <strong>
            11.3. Categories of personal data and retention period:
          </strong>
        </small>
        <small className="d-block mt-1">
          <table
            style={{
              backgroundColor: "#E4E4E4",
              border: "1px solid #AAA",
              borderCollapse: "collapse",
            }}
            cellPadding={5}
            cellSpacing={2}
          >
            <tr>
              <th>
                <strong>Purpose of processing</strong>
              </th>
              <th>
                <strong>Purpose of processing</strong>
              </th>
              <th>
                <strong>Purpose of processing</strong>
              </th>
            </tr>
            <tr>
              <td>
                Civil status, official identity documents and data,
                identification data, contact details, images, voices...
              </td>
              <td>Email, first and last name</td>
              <td>5 years</td>
            </tr>
            <tr>
              <td>
                Economic and financial information (income, financial situation,
                tax situation, transactions, etc.)
              </td>
              <td>
                Token for connection to banking APIs, and financial situation
                (balance, transactions and account number)
              </td>
              <td>5 years</td>
            </tr>
            <tr>
              <td>
                Connection/navigation data (IP address, logs, etc.) and location
                data (travel, GPS, GSM data, etc.)
              </td>
              <td>IP address and user agent</td>
              <td>1 year</td>
            </tr>
          </table>
        </small>
        <small className="d-block mt-1">
          <strong>
            11.4. Countries where the data is processed by the Processor
          </strong>
        </small>
        <small className="d-block mt-1">France, Switzerland</small>
        <p className="ml-2 mt-3">
          <strong>12. Intellectual property rights</strong>
        </p>
        <small className="d-block mt-1">
          <strong>12.1. Property rights</strong>
        </small>
        <small className="d-block mt-1">
          All intellectual property rights relating to the Platform, including
          the software, trademarks and content, are the exclusive property of
          SCEME.
        </small>
        <small className="d-block mt-1">
          <strong>12.2. Limited licence</strong>
        </small>
        <small className="d-block mt-1">
          SCEME grants Schuman Financial a non-exclusive, non-transferable
          licence to use the Platform and therefore to offer their Client the
          right to use the Platform in accordance with these GTCU.
        </small>
        <p className="ml-2 mt-3">
          <strong>13. Limitation of liability</strong>
        </p>
        <small className="d-block mt-1">
          Under no circumstances shall the company, its affiliates, or their
          licensors, service providers, employees, agents, stockholders,
          officers, or directors be liable for any damages of any kind, whether
          arising in contract, tort, or otherwise, arising out of or in
          connection with your use or inability to use the website, any linked
          websites, website content, or any services or items obtained through
          the website. this includes, but is not limited to, direct, indirect,
          special, incidental, consequential, or punitive damages, such as
          personal injury, pain and suffering, emotional distress, loss of
          revenue, loss of profits, loss of business, anticipated savings, loss
          of use, loss of goodwill, loss of data, and any other damages, even if
          foreseeable.
        </small>
        <small className="d-block mt-1">
          The total liability of the company, its subsidiaries and affiliates,
          and their licensors, service providers, employees, agents, officers,
          and directors to any party, regardless of the form of action, shall
          not exceed the lesser of 100€ or the amount of fees paid by you to
          SCEME in the twelve months preceding the event giving rise to the
          claim.
        </small>
        <small className="d-block mt-1">
          This limitation of liability does not affect any liability that cannot
          be excluded or limited under applicable law.
        </small>
        <p className="ml-2 mt-3">
          <strong>14. Disclaimer of Warranties</strong>
        </p>
        <small className="d-block mt-1">
          Your use of the Website, its content, and any Services or items
          obtained through the Website is at your own risk. The Website, its
          content, and any Services or items obtained through the Website are
          provided &quot;as is&quot; and &quot;as available,&quot; without any
          warranties of any kind, either express or implied.
        </small>
        <small className="d-block mt-1">
          Neither the Company nor anyone associated with the Company makes any
          warranty or representation regarding the completeness, security,
          reliability, quality, accuracy, or availability of the Website.
          Neither the Company nor anyone associated with the Company represents
          or warrants that the Website, its content, or any Services or items
          obtained through the Website will be accurate, reliable, error-free,
          or uninterrupted, that defects will be corrected, that the Website or
          the server that makes it available are free of viruses or other
          harmful components, or that the Website, or any Services or items
          obtained through the Website will otherwise meet your needs or
          expectations.
        </small>
        <small className="d-block mt-1">
          The Company disclaims all warranties of any kind, whether express or
          implied, statutory, or otherwise, including but not limited to any
          warranties of merchantability, non-infringement, and fitness for a
          particular purpose.
        </small>
        <small className="d-block mt-1">
          This disclaimer does not affect any warranties that cannot be excluded
          or limited under applicable law.
        </small>
        <p className="ml-2 mt-3">
          <strong>15. Indemnification</strong>
        </p>
        <small className="d-block mt-1">
          You agree to defend, indemnify, and hold harmless the Company, its
          affiliates, stockholders, licensors, service providers, and their
          respective officers, directors, employees, contractors, agents,
          licensors, suppliers, successors, and assigns from and against all
          Claims arising out of or relating to:
        </small>
        <small className="d-block mt-1">
          • Your violation of these Terms; <br />
          • Your use of the Website and Services; <br />• Your Digital Assets
          associated with your use of the Services; or <br />
          • Your infringement or misappropriation of the rights of any other
          person or entity. <br />
          If you are obligated to indemnify an Indemnified Party, SCEME (or the
          applicable Indemnified Party, at its discretion) may control any
          action or proceeding and determine whether to settle and on what
          terms. You agree to cooperate with SCEME in the defense.
        </small>
        <p className="ml-2 mt-3">
          <strong>16. Termination</strong>
        </p>
        <small className="d-block mt-1">
          The Client may terminate his access to the Platform at any time by
          ceasing to use the services and notifying Schuman Financial.
        </small>
        <small className="d-block mt-1">
          These GTCU are governed by French law. In the event of a dispute, the
          competent courts will be those of Paris - France.
        </small>
        <p className="ml-2 mt-3">
          <strong>17. Applicable law and jurisdiction</strong>
        </p>
        <small className="d-block mt-1">
          These GTCU are governed by French law. In the event of a dispute, the
          competent courts will be those of Paris - France.
        </small>
        <p className="ml-2 mt-3">
          <strong>18. Contact</strong>
        </p>
        <small className="d-block mt-1">
          If you have any questions or concerns about these GTU, you can contact
          us at the following address: contact@sceme.io
        </small>
      </div>
    );
  }
}

export default CguContent;
