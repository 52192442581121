import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Label,
  Button,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { referenceIban } from "../../helpers/api";
import Loader from "react-loader-spinner";

const IBAN = require("iban");
const BIC = require("bic");

class NewIdbank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bank: null,
      iban: null,
      bic: null,
      error: null,
      validBic: false,
      validIban: false,
      loading: false,
    };
  }

  registerIban = () => {
    this.setState({ error: null, success: null, loading: true });
    this._asyncRequest = referenceIban(
      this.state.bank,
      this.state.iban,
      this.state.bic
    )
      .then((res) => {
        if (res.status === "SUCCESS") {
          window.location = "/idbanks";
        } else {
          this.setState({ error: res.error, loading: false });
        }
      })
      .catch((error) => {
        this.setState({ error: error.toString(), loading: false });
      });
  };

  updateIban = (event) => {
    this.setState({
      iban: event.target.value,
      country: null,
      validIban: false,
      selectedIban: null,
    });
    if (IBAN.isValid(event.target.value)) {
      const bban = IBAN.toBBAN(event.target.value, "-");
      console.log(bban);
      this.setState({ validIban: true });
    } else this.setState({ validIban: false });
  };

  updateBic = (event) => {
    this.setState({ bic: event.target.value });
    if (BIC.isValid(event.target.value)) this.setState({ validBic: true });
    else this.setState({ validBic: false });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">
                      <i className="bx bx-money"></i> Reference your Bank
                      account on SCHUMAN Financial
                    </h4>

                    <Row className="mb-2">
                      <Col sm="12">
                        <Label>New IBAN</Label>
                        {this.state.error && (
                          <p className="text-danger">{this.state.error}</p>
                        )}
                        <Row>
                          <Col md="8">
                            <InputGroup className="mb-2">
                              <InputGroupAddon addonType="prepend">
                                <span className="input-group-text">
                                  Bank name
                                </span>
                              </InputGroupAddon>
                              <Input
                                type="text"
                                className="form-control"
                                onChange={(e) =>
                                  this.setState({ bank: e.target.value })
                                }
                                autoComplete="off"
                              />
                            </InputGroup>
                          </Col>
                          <Col md="6">
                            <InputGroup className="mb-2">
                              <InputGroupAddon addonType="prepend">
                                <span className="input-group-text">IBAN</span>
                              </InputGroupAddon>
                              <Input
                                type="text"
                                className="form-control"
                                onChange={this.updateIban}
                                autoComplete="off"
                              />
                              <InputGroupAddon addonType="append">
                                {this.state.validIban ? (
                                  <h4 className="ml-2 text-success">
                                    <b className="bx bx-check-shield"></b>
                                  </h4>
                                ) : (
                                  <h4 className="ml-2 text-danger">
                                    <b className="bx bx-shield-x"></b>
                                  </h4>
                                )}
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>
                          <Col md="6">
                            <InputGroup className="mb-2">
                              <InputGroupAddon addonType="prepend">
                                <span className="input-group-text">
                                  BIC/SWIFT
                                </span>
                              </InputGroupAddon>
                              <Input
                                type="text"
                                className="form-control"
                                onChange={this.updateBic}
                                autoComplete="off"
                              />
                              <InputGroupAddon addonType="append">
                                {this.state.validBic ? (
                                  <h4 className="ml-2 text-success">
                                    <b className="bx bx-check-shield"></b>
                                  </h4>
                                ) : (
                                  <h4 className="ml-2 text-danger">
                                    <b className="bx bx-shield-x"></b>
                                  </h4>
                                )}
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>
                        </Row>
                        {this.state.validIban && this.state.validBic && (
                          <React.Fragment>
                            {this.state.loading ? (
                              <React.Fragment>
                                <Loader
                                  type="Puff"
                                  color="#50a5f1"
                                  height={50}
                                  width={50}
                                />
                                <p className="mt-2 text-info">
                                  Notification sent onto your TrustBuilder
                                  Authenticator application.{" "}
                                  <strong>
                                    Set your pin code to save IBAN...
                                  </strong>
                                </p>
                              </React.Fragment>
                            ) : (
                              <Button
                                type="button"
                                color="light"
                                onClick={this.registerIban}
                              >
                                Reference Iban
                              </Button>
                            )}
                          </React.Fragment>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default NewIdbank;
